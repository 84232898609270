/* 폰트 */
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-square-round.css');
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-barun-gothic.css');
/*테일윈드*/
@tailwind base;
@tailwind components;
@tailwind utilities;



html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'NanumBarunGothic','NanumSquareRound', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .button-style {
    @apply text-base font-semibold leading-none text-white py-3 px-10 bg-violet-700 rounded hover:bg-violet-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none;
  }
}